import {Elements, useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';


const CheckoutForm = ({ submitSubscribe }) => {
    const { REACT_APP_PAYMENT_PKEY } = process.env;
    const stripePromise = loadStripe(REACT_APP_PAYMENT_PKEY);
    const stripe = useStripe();
    var elements = useElements();

      const elementsOptions = {
        currency: 'aud',
        style: {
            base: {
                // lineHeight: '40px',
                fontSize: '16px'
            }
        },
      };
      
    const submitSubscription = async (e) => {
        e.preventDefault();
        // dispatch(createCardTokenThunk(cardData));
        // const cardElement = elements.getElement(CardNumberElement);
        // const cardToken = stripe.createToken(elements);
        // var cardNumber = elements.create('cardNumber');
        // cardNumber.mount('#card-number');
        // var cardExpiry = elements.create('cardExpiry');
        // cardExpiry.mount('#card-expiry');
        // var cardCvc = elements.create('cardCvc');
        // cardCvc.mount('#card-cvc');
        // const cardNumber = elements.create('cardNumber');
        // console.log(cardNumber);
        // const cardToken = stripe.createToken(cardNumber);
        // console.log(cardToken);
        // console.log(cardElement, cardToken);
        

        const cardElement = elements.getElement(CardNumberElement);

        const paymentMethod = await stripe.createPaymentMethod({ type: 'card', card: cardElement})
        .then((payload) => payload);
        submitSubscribe(paymentMethod)
    }

    return (
        <>
            <Form onSubmit={submitSubscription}>
                <div className='form-payment'>
                    <Row>
                        <Col lg={12}>
                            Card Information <br />
                            <Form.Label>Card Number</Form.Label>
                            <div className='cardContainer'>
                                <CardNumberElement options={elementsOptions}/>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <Form.Label>Expiry</Form.Label>
                            <div className='cardContainer'>
                                <CardExpiryElement options={elementsOptions}/>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <Form.Label>CVC</Form.Label>
                            <div className='cardContainer'>
                                <CardCvcElement options={elementsOptions}/>
                            </div>
                        </Col>
                        
                        <Col lg={12} className='text-right'>
                            <Button variant="primary" type='submit'>Pay</Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    )
};

export default CheckoutForm;