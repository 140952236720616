import { customFetch, globalKeys } from "../../utils/tools";

export const currencyList = async ({
    user_id,
}) => {
    return await customFetch({
        api_uri: 'crypto/list_crypto',
        body: {
            user_id
        },
        method: "POST"
    });
}


