import { createSlice } from "@reduxjs/toolkit"
import { globalKeys } from "../../utils/tools";
import { currencyListThunk } from "./cryptocurrencyThunks";

const initialState = {
    status: "initial", // initial, loading, error, loaded
    message: "",
    event: "",
    data: {},
}

export const cryptoCurrencySlice = createSlice({
    name: "cryptocurrency",
    initialState,
    reducers: {
        resetCryptoCurrencyState: (state) => {
            state.status = "initial"; // initial, loading, error, loade;
            state.message = "";
            state.event = "";
            state.data = {};
        }
    },
    extraReducers: (builder) => {
        // registration
        builder.addCase(currencyListThunk.pending, (state) => {
            state.event = 'list';
            state.status = "loading";
        }).addCase(currencyListThunk.fulfilled, (state, action) => {
            state.event = 'list';
            var response = action.payload;
            state.data = response;
            state.message = 'Success';
            state.status = "loaded";
        }).addCase(currencyListThunk.rejected, (state, action) => {
            state.event = 'list';
            state.status = "error";
            state.message = action.error?.message;
        })
    }
});

export const { resetCryptoCurrencyState } = cryptoCurrencySlice.actions;

export const selectCryptoCurrencySlice = (state) => state.cryptocurrency;

export default cryptoCurrencySlice.reducer;