import React, { useEffect, useState, useRef, Component } from 'react';
import { Container, Row, Col, Table, Button, Modal, Form, Card, Image, Dropdown } from 'react-bootstrap';
import { SideNav } from '../../features/sidenav/SideNav';
import { Header } from '../../features/mobile_header/Header';
import Chart from "react-apexcharts";
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectDevice } from '../../features/devices/deviceSlice';
import { selectCryptoCurrencySlice } from '../../features/cryptocurrency/cryptocurrencySlice';
import { currencyListThunk } from '../../features/cryptocurrency/cryptocurrencyThunks';
import { deviceListThunk, updateDeviceThunk } from '../../features/devices/deviceThunks';
import serializer from 'form-serialize';
import { useAuth } from '../../providers/AuthProvider';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { Select as VirtualizeSelect } from 'react-select-virtualized';
import AsyncSelect from "react-select/async";
import { createFilter } from "react-select";
import CryptoOptionList from '../../components/CryptoOptionList';
import { FixedSizeList as List } from "react-window";
import useDidMountHook from '../../hooks/useDidMountHook';

function Devices() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	var [user] = useAuth();
	const { status, event, message, data } = useSelector(selectDevice);
	const { status: currencyStatus , event: currencyEvent, message: currencyMessage, data: currencyData } = useSelector(selectCryptoCurrencySlice);
	const form = useRef(null);

	const available_screens = [
		{ value: 1, src: require('../../assets/images/screens/screen-1.png'), name: 'Screen 1' },
		{ value: 2, src: require('../../assets/images/screens/screen-2.png'), name: 'Screen 2' },
		{ value: 3, src: require('../../assets/images/screens/screen-3.png'), name: 'Screen 3' },
		{ value: 4, src: require('../../assets/images/screens/screen-4.png'), name: 'Screen 4' },
		{ value: 5, src: require('../../assets/images/screens/screen-5.png'), name: 'Screen 5' },
	]
	// let available_crypto_currency = [
	// 	{ name: 'Bitcoin', value: 'BTC' },
	// 	{ name: 'XRP', value: 'XRP' },
	// 	{ name: 'Etherium', value: 'ETH' },
	// 	{ name: 'Siacoin', value: 'SC' },
	// ]
	const primary_currency = [
		{ label: 'USD', value: 'USD' },
		{ label: 'AUD', value: 'AUD' },
	]


	// const dropdownRef = useRef(null);
    // const [isOpen, setIsOpen] = useState(false); 
    // const dropDownShow = () => { 
    //     setIsOpen(!isOpen); 
    // }; 


	const [selectedDevice, setSelectedDevice] = useState({});
	const [showEditDevice, setShowEditDevice] = useState(false);

	useDidMountHook(() => {
		dispatch(deviceListThunk({ user_id: user?.id }))
	});
	// let devices_list = [];
	useEffect(() => {
		
		if (currencyStatus === 'initial') {
			dispatch(currencyListThunk({ user_id: user?.id }))
		}

		if (showEditDevice && event === "update" && status === "update_success") {
			console.log({ event, status });
			toast.success('Successfuly updated device', {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "light",
			});

			let id = user;
			dispatch(deviceListThunk({ user_id: id._id }))
			handleClose();
		}

		// const handleOutSideClick = (event) => {
		// 	if (!dropdownRef.current?.contains(event.target)) {
		// 	  setIsOpen(false);
		// 	}
		//   };
	  
		// window.addEventListener("mousedown", handleOutSideClick);
	
		// return () => {
		// 	window.removeEventListener("mousedown", handleOutSideClick);
		// };
		
	}, [status, event, currencyStatus, currencyEvent])

	const handleClose = () => setShowEditDevice(false);
	const handleShow = () => setShowEditDevice(true);

	const clickEdit = (device) => {
		setSelectedDevice({ ...device, id: device._id, user_id: user._id });
		handleShow();
	};

	const [rangeValue, setRangeValue] = useState(10);
	const rangeChange = (e) => {
		const { name, value } = e.target;
		setRangeValue(value);
	};

	const submitUpdateForm = () => {
		// navigate('/register/device')
		if (selectedDevice && selectedDevice.screen === 4 && selectedDevice.cryptoCurrency.length > 4) {
			toast.warning('You can only select 4 crypto currencies', {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "light",
			});
		} else if (selectedDevice && selectedDevice.screen === 4 && selectedDevice.cryptoCurrency.length < 4) {
			toast.warning('Please select 4 crypto currencies', {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "light",
			});
		} else {
			dispatch(updateDeviceThunk(selectedDevice));
		}
	}

	const selectCryptoCurrency = (currencies) => {
		// if (selectedDevice && selectedDevice.cryptoCurrency && selectedDevice.cryptoCurrency.length > 0 && selectedDevice.cryptoCurrency.find((curr) => currency === curr)) {
		// 	setSelectedDevice({ ...selectedDevice, cryptoCurrency: selectedDevice.cryptoCurrency.filter((curr) => curr != currency) });
		// } else {
		// 	if (!selectedDevice.cryptoCurrency) {
		// 		setSelectedDevice({ ...selectedDevice, cryptoCurrency: [currency] })
		// 	} else {
		// 		setSelectedDevice({ ...selectedDevice, cryptoCurrency: [...selectedDevice.cryptoCurrency, currency] })
		// 	}
		// }
		setSelectedDevice({ ...selectedDevice, cryptoCurrency: currencies.map(currency => currency.value) })
	}

	
	class MenuList extends Component {
		render() {
		const height = 35;
		const { options, children, maxHeight, getValue } = this.props;
		const [value] = getValue();
		const initialOffset = options.indexOf(value) * height;
	
		return (
				<List
				height={maxHeight}
				itemCount={children.length}
				itemSize={height}
				initialScrollOffset={initialOffset}
				>
				{({ index, style }) => <div style={style}>{children[index]}</div>}
				</List>
			);
		}
	}

	const optionsSelect = [
		{
		  value: 1,
		  label: `guiyep`,
		}
	];
	// style={{backdropFilter: `brightness(${rangeValue}%)`}}
	return (
		<>
			<Header page={'devices'} />
			<Container fluid className="element-page main h-100 pt-5 pb-5">
				<Row className="h-100">
					<Col lg={12}>
						<Row className="content align-items-center">
							<Col lg={10} md={12}><h1>Your Devices</h1></Col>
							<Col lg={2} sm={6} className='pb-1 text-right'>
								<Button className="" variant="primary" onClick={() => navigate('/add-device')}>Add device</Button>
							</Col>
						</Row>

						<Row className="content align-items-stretch">
							{(() => {
								if (status === "loaded") {
									var devices_list = data;

									if (devices_list.length > 0) {
									return (
										<>
											{devices_list.map((device, i) => (
												<Col lg={4} key={i} className='mb-3'>
													<Card className='mx-2' onClick={() => window.location.href = `/update-device/${device.device_id}`}>
														<Card.Body>
															<Card.Title>{device.name}</Card.Title>
															<Card.Text className={(device?.paymentStatus === 'Cancelled' ? 'cancel' : (device.isPaidSubscription ? 'active' : 'free')) + ' status'}>{device?.paymentStatus === 'Cancelled' ? 'Cancelled' : (device.isPaidSubscription ? 'Paid' : 'Free')}</Card.Text>
															<Card.Text>{device.device_id}</Card.Text>
															<Card.Text className='mt-5 crypto-logo'>
																{(device?.cryptoCurrency ?? []).map((crypto, j) => (
																	<span className='cryptox avatar'>{crypto}</span>
																))}
															</Card.Text>
														</Card.Body>
													</Card>
												</Col>
											))}
										</>

									)
								} else {
									return (
										<>
											<Col className='col-12 text-center'>No device yet</Col>
										</>
									)
								}
							}

								if (status === "loading") {
									return (
										<>
											<Col lg={12}>Loading...</Col>
										</>
									)
								}
							})()}

							<Col lg={4} >
								<Card className='mb-5 mx-2 add-new d-none' style={{
									padding: "3rem 0px"
								}} onClick={() => navigate('/add-device')}>
									<Card.Body>
										<Icon.Plus /> Add Device
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
				<div >
					<Modal show={showEditDevice} onHide={handleClose} size="xl" fullscreen={'lg-down'}>
						<Modal.Header closeButton>
							<Modal.Title>Update Device</Modal.Title>
						</Modal.Header>
						<Modal.Body >
							<Row>
								<Col md={6} sm={12}>
									<Form.Label>Device Name</Form.Label>
									<Form.Control type="text" name="name" value={selectedDevice.name} onChange={(e) => setSelectedDevice({ ...selectedDevice, name: e.target.value })} />
								</Col>
								<Col md={6} sm={12}>
									<Form.Label>Device ID</Form.Label>
									<Form.Control type="text" name="name" disabled="true" readOnly="true" value={selectedDevice.device_id} />
								</Col>
							</Row>
							<Row>
								<Col md={12} sm={12}>
									<Form.Label>Screen</Form.Label>
								</Col>

								{available_screens.map((screen, i) => (
									<Col>
										<Form.Group onClick={() => setSelectedDevice({ ...selectedDevice, screen: screen.value })} className={`form-group ` + (selectedDevice.screen === screen.value ? 'selected' : '')}>
											<Image src={screen.src}></Image><br />
											<Form.Label>{screen.name}</Form.Label>
										</Form.Group>
									</Col>
								))}
							</Row>
							<Row>
								<Col md={6} sm={12}>
									<Form.Label>Crypto Currency</Form.Label>
									{(() => {
										if (currencyStatus === "loaded") {
											const availableCryptoCurrency = currencyData.data.map((currency, i) => {
												return { label: currency.name + ' (' + currency.symbol + ')', value: currency.symbol};
											});
											
											const loadOptions = (inputValue, callback) => {
												setTimeout(() => {
												callback(availableCryptoCurrency);
												}, 1000);
											};
											if (selectedDevice && selectedDevice.screen === 4) {
												return (
													<>
														<AsyncSelect components={{ MenuList }} 
															cacheOptions 
															isMulti={true}
															defaultOptions
															defaultValue={
																selectedDevice?.cryptoCurrency && selectedDevice?.cryptoCurrency.length > 0 ? 
																availableCryptoCurrency.filter(option => selectedDevice?.cryptoCurrency?.includes(option.value)) : ''
															}
															loadOptions={loadOptions}
															filterOption={createFilter({ ignoreAccents: false })}
															onChange={(e) => selectCryptoCurrency(e) }
															/>
													</>
												)
											} else {
											
												return (
													<>
														<VirtualizeSelect options={availableCryptoCurrency}
																defaultValue={
																	selectedDevice?.cryptoCurrency && selectedDevice?.cryptoCurrency.length > 0 ?  availableCryptoCurrency.find(option => 
																	option.value === selectedDevice?.cryptoCurrency[0]) : ''
																}
																onChange={(e) => setSelectedDevice({ ...selectedDevice, cryptoCurrency: e.value })}
																 />
													</>
												)
											}
										}							
									})()}	
								</Col>
								
								<Col md={6} sm={12}>
									<Form.Label>Primary Currency</Form.Label>
									
									<Select options={primary_currency}
														defaultValue={primary_currency.find(currency => currency.value === selectedDevice.primaryCurrency)}
														onChange={(e) => setSelectedDevice({ ...selectedDevice, primaryCurrency: e.value })} />		
								</Col>
							</Row>
							<Row>
								<Col md={6} sm={12}>
									<Form.Label>Mode</Form.Label>

									<Row>
										<Col md={4}>
											<Form.Group onClick={() => setSelectedDevice({ ...selectedDevice, mode: 'CryptoX' })} className={`form-group with-icon avatar ` + (selectedDevice.mode === 'CryptoX' ? 'selected' : '')}>
												<div className='cryptox avatar'></div>
												<Form.Label>
													CryptoX
												</Form.Label>
											</Form.Group>
										</Col>
										<Col md={4}>
											<Form.Group onClick={() => setSelectedDevice({ ...selectedDevice, mode: 'Light' })} className={`form-group with-icon avatar ` + (selectedDevice.mode === 'Light' ? 'selected' : '')}>
												<div className='light avatar'></div>
												<Form.Label>
													Light
												</Form.Label>
											</Form.Group>
										</Col>

										<Col md={4}>
											<Form.Group onClick={() => setSelectedDevice({ ...selectedDevice, mode: 'Dark' })} className={`form-group with-icon avatar ` + (selectedDevice.mode === 'Dark' ? 'selected' : '')}>
												<div className='dark avatar'></div>
												<Form.Label>
													Dark
												</Form.Label>
											</Form.Group>
										</Col>
									</Row>
								</Col>
								<Col md={6} sm={12}>
									<Form.Label>Brightness {selectedDevice?.brightness}%</Form.Label>
									<Form.Range onChange={(e) => setSelectedDevice({ ...selectedDevice, brightness: e.target.value })} value={selectedDevice?.brightness} name='brightness' min="0" max="100" />
								</Col>
							</Row>
						</Modal.Body>

						<Modal.Footer>
							<Button className='btn-sm' variant="secondary" onClick={handleClose}>Close</Button>
							<Button className='btn-sm' variant="primary" onClick={submitUpdateForm}>Update</Button>
						</Modal.Footer>
					</Modal>
				</div>
			</Container>
		</>
	)
}


export default Devices
