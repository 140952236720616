import React, { useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Steps } from '../../features/steps/Steps';


export function RegisterProfile() {
    const navigate = useNavigate();

    return (
        <>
            <Container fluid className="element-page register-account h-100">
                <Row className="align-items-center h-100">
                    <Steps step={3} />
                    <Col lg={8} className="form-account">
                        <h1>Setup Profile</h1>

                        <Row>
                            <Col lg={6}>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" placeholder="John" />
                            </Col>
                            <Col lg={6}>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Citizen" />
                            </Col>
                            <Col lg={6}>
                                <Form.Label>Contact No.</Form.Label>
                                <Form.Control type="text" placeholder="0044" />
                            </Col>
                        </Row>
                        <Row className="align-items-center justify-content-center">
                            <Col lg={6}>
                                <Button variant="secondary" onClick={() => { navigate('/register/account') }}>Cancel</Button>
                            </Col>
                            <Col lg={6} className="text-right">
                                <Button variant="secondary" onClick={() => { navigate('/register/device') }}>Previous</Button>
                                <Button variant="primary" onClick={() => { navigate('/register/random') }}>Next</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

