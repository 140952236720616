import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Image, Form, Button, Nav } from 'react-bootstrap';
import { useNavigate, NavLink } from "react-router-dom";
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
} from 'cdbreact';
import { logout } from '../../features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAuth } from '../auth/authThunks';


export function SideNav(props) {
    const dispatch = useDispatch();

    const isActivePage = (page, currentpage) => {
        if (page === currentpage) return 'active';

        return '';
    }

    return (
        <>
            <CDBSidebar textColor="#fff" backgroundColor="#000" className='d-none'>
                <a href="/" className="text-decoration-none d-md-block d-none" style={{ color: 'inherit' }}>
                    <Image src={require('../../assets/images/logo.png')} />
                </a>
                <CDBSidebarHeader className="d-md-none d-block" prefix={<i className="fa fa-bars fa-large"></i>}>
                    <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
                        <Image src={require('../../assets/images/logo.png')} />
                    </a>
                </CDBSidebarHeader>

                <CDBSidebarContent className="sidebar-content d-md-block d-none">
                    <CDBSidebarMenu>
                        <NavLink exact="true" to="/devices" className={isActivePage('devices', props.page)}>
                            <CDBSidebarMenuItem icon="columns">Devices</CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink exact="true" to="/profile" className={isActivePage('profile', props.page)}>
                            <CDBSidebarMenuItem icon="user">Profile</CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink exact="true" to="/" onClick={() => dispatch(logoutAuth()) }>
                            <CDBSidebarMenuItem icon="arrow-right">Logout</CDBSidebarMenuItem>
                        </NavLink>
                    </CDBSidebarMenu>
                </CDBSidebarContent>

                {/* <CDBSidebarFooter style={{ textAlign: 'center' }}>
				<div
					style={{
					padding: '20px 5px',
					}}
				>
					Sidebar Footer
				</div>
				</CDBSidebarFooter> */}
            </CDBSidebar>
            {/* <Col lg={2} className="sidenav">
                <ul className="">
                    <li className={isActivePage('dashboard', props.page)}>
                        <h3>Dashboard</h3>
                    </li>
                    <li className={isActivePage('profile', props.page)}>
                        <h3>Profile</h3>
                    </li>
                    <li className={isActivePage('random', props.page)}>
                        <h3>Random</h3>
                    </li>
                    <li className={isActivePage('settings', props.page)}>
                        <h3>Settings</h3>
                    </li>
                </ul>
            </Col> */}
        </>
    )
}