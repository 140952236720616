import { customFetch, globalKeys } from "../../utils/tools";

export const deviceList = async ({
    user_id,
}) => {
    return await customFetch({
        api_uri: 'device/list_devices',
        body: {
            user_id
        },
        method: "POST"
    });
}


export const updateDevice = async (payload) => {
    return await customFetch({
        api_uri: 'device/update_device',
        body: payload,
        method: "POST"
    });
}

export const deviceDetail = async ({
    device_id,
}) => {
    return await customFetch({
        api_uri: 'device/get_device',
        body: {
            device_id
        },
        method: "POST"
    });
}

export const devicePayment = async ({
    amount,
    currency,
    status,
    paymentDate,
    userId,
    deviceId,
    subscriptionType,
    stripePaymentId,
    stripeCustomerId,
    stripeInvoiceId,
    stripeSubscriptionId,
    description,
    receiptUrl,
    metadata,
}) => {
    return await customFetch({
        api_uri: 'payments',
        body: {
            amount,
            currency,
            status,
            paymentDate,
            userId,
            deviceId,
            subscriptionType,
            stripePaymentId,
            stripeCustomerId,
            stripeInvoiceId,
            stripeSubscriptionId,
            description,
            receiptUrl,
            metadata,
        },
        method: "POST"
    });
}

export const deviceSubscribe = async ({
    payment_method,
    deviceId,
    email,
}) => {
    return await customFetch({
        api_uri: 'stripe/make-payment',
        body: {
            payment_method,
            deviceId,
            email,
        },
        method: "POST",
        includeHeaders: true,
    });
}
export const cancelDeviceSubscription = async ({
    subscriptionId
}) => {
    return await customFetch({
        api_uri: 'stripe/cancel-subscription',
        body: {
            subscriptionId
        },
        method: "POST",
        includeHeaders: true,
    });
}
