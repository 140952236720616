import { createAsyncThunk } from "@reduxjs/toolkit";
import { stripeCreateCustomer, stripeCreateCardToken, stripeAttachCard, stripeSubscribe } from "./stripeApi";

export const subscribeCustomerThunk = createAsyncThunk(
    'stripe/customers/create',
    async (cardToken, data) => {
        const customer = await stripeCreateCustomer(data);
        // const cardToken = await stripeCreateCardToken(data);
        await stripeAttachCard(cardToken, customer?.id ?? '');
        // return await stripeSubscribe(customer?.id ?? '', "price_1MPIvxLo65eNGq4WOhsVFuw6");
        return await stripeSubscribe(customer?.id ?? '', "price_1PaX7AFwLWThfY5C7QbpxfUp");
        // subscribe call here
    }
)

export const createCardTokenThunk = createAsyncThunk(
    'stripe/customers/card/token',
    async (data) => {
        return await stripeCreateCardToken(data);
    }
)