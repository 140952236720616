import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import stripeReducer from '../features/stripe/stripeSlice';
import counterReducer from '../features/counter/counterSlice';
import deviceReducer from '../features/devices/deviceSlice';
import currencyReducer from '../features/cryptocurrency/cryptocurrencySlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    stripe: stripeReducer,
    device: deviceReducer,
    cryptocurrency: currencyReducer,
  },
});
