import { createAsyncThunk } from "@reduxjs/toolkit";
import { currencyList } from "./cryptocurrencyApi";


export const currencyListThunk = createAsyncThunk(
    'crypto/list_crypto',
    async (data) => {
        return await currencyList(data);
    }
)
