import React, { useEffect, useRef, useState } from 'react';
import { Navbar, Container, Row, Col, Form, Button, Breadcrumb } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import serializer from 'form-serialize';
import { checkSession, selectAuth } from '../features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { loginThunk, } from '../features/auth/authThunks';
import { ToastContainer, toast } from 'react-toastify';

const Login = (props) => {
    const navigate = useNavigate();
    const userState = useSelector(selectAuth);
    const dispatch = useDispatch();
    const form = useRef(null);

    const submitAccountForm = () => {
        // navigate('/register/device')
        const body = serializer(form.current, { hash: true });

        console.log(body);
        dispatch(loginThunk(body));
    }




    return (
        <>
            <Navbar fixed="top" bg="dark" variant="dark">
                <Container className="justify-content-center">
                    <Navbar.Brand href="/">
                        <img
                            alt="cryptox-logo"
                            src={require('../assets/images/logo.png')}
                            className="d-inline-block align-top"
                        />{' '}
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <Container fluid className="element-page register-account h-100">
                <Row className="align-items-center h-100 justify-content-center">
                    <Col lg={8} className="login-form-account">
                        <ToastContainer />
                        <Form ref={form}>
                            <h1>Login</h1>

                            <Row>
                                <Col lg={12}>
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="email" name="email" placeholder="johncitizen@email.com" />
                                </Col>
                                <Col lg={12}>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" name="password" placeholder="Password" />
                                </Col>
                                <Col lg={12}><br /></Col>
                            </Row>
                            <Row>
                                {(() => {
                                    const { status, message, } = userState;

                                    if (status === "initial") {
                                        return (
                                            <Col lg={12}>
                                                <Button className="mx-auto d-table" variant="secondary" onClick={() => submitAccountForm()}>Next</Button>
                                            </Col>
                                        )
                                    }

                                    if (status === "loading") {
                                        return (
                                            <Col lg={12}>
                                                <Button className="mx-auto d-table" variant="secondary" >Loading</Button>
                                            </Col>
                                        )
                                    }

                                    if (["error_no_session", "error"].includes(status)) {
                                        return (
                                            <Col lg={12}>
                                                <Button className="mx-auto d-table" variant="secondary" onClick={() => submitAccountForm()}>Login</Button>
                                            </Col>
                                        )
                                    }

                                    return (
                                        <Col lg={12}>
                                            <Button variant="primary" onClick={() => submitAccountForm()}>Logout Test</Button>
                                        </Col>
                                    )

                                    return (
                                        <Col lg={12}>
                                            <Button variant="primary" onClick={() => submitAccountForm()}>Next</Button>
                                        </Col>
                                    )
                                })()}
                                <Col lg={12}>
                                    <Breadcrumb className="mx-auto d-table">
                                        Don't have an account? &nbsp;<Breadcrumb.Item href="/register/account"> Register here</Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Login

