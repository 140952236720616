import { createSlice } from "@reduxjs/toolkit"
import { globalKeys } from "../../utils/tools";
import { cancelDeviceSubsriptionThunk, createDeviceThunk, deviceListThunk, devicePaymentThunk, deviceSubscribeThunk, getDeviceDetailThunk, updateDeviceThunk } from "./deviceThunks";

const initialState = {
    status: "initial", // initial, loading, error, loaded
    message: "",
    event: "",
    data: {},
}

export const deviceSlice = createSlice({
    name: "device",
    initialState,
    reducers: {
        resetDeviceState: (state) => {
            state.status = "initial"; // initial, loading, error, loade;
            state.message = "";
            state.event = "";
            state.data = {};
        }
    },
    extraReducers: (builder) => {
        // registration
        builder.addCase(deviceListThunk.pending, (state) => {
            state.event = 'list';
            state.status = "loading";
        }).addCase(deviceListThunk.fulfilled, (state, action) => {
            state.event = 'list';
            var response = action.payload;
            state.data = response;
            state.message = 'Success';
            state.status = "loaded";
        }).addCase(deviceListThunk.rejected, (state, action) => {
            state.event = 'list';
            state.status = "error";
            state.message = action.error?.message;
        })
        // get current user

        builder.addCase(updateDeviceThunk.pending, (state) => {
            state.event = 'update';
            state.status = "loading";
        }).addCase(updateDeviceThunk.fulfilled, (state, action) => {
            var response = action.payload;
            state.data = response.device;
            state.message = 'Success';
            state.event = 'update';
            state.status = "update_success";
        }).addCase(updateDeviceThunk.rejected, (state, action) => {
            state.event = 'update';
            state.status = "error";
            state.message = action.error?.message;
        })

        builder.addCase(createDeviceThunk.pending, (state) => {
            state.event = "create_device"
            state.status = "loading";
        }).addCase(createDeviceThunk.fulfilled, (state, { payload }) => {
            state.event = "create_device"

            if (!payload?.device) {
                state.status = "error";
                state.message = payload?.message ?? "Device Id already exists"
            } else {
                state.status = "success";
                state.message = payload?.message
                state.data = payload?.device;
            }
        }).addCase(createDeviceThunk.rejected, (state, action) => {
            state.event = "create_device"
            state.status = "error";
            state.message = action.error?.message;
        })

        builder.addCase(getDeviceDetailThunk.pending, (state) => {
            state.event = "detail_device"
            state.status = "loading";
        }).addCase(getDeviceDetailThunk.fulfilled, (state, { payload }) => {
            state.event = "detail_device"

            if (payload && payload.length <= 0) {
                state.status = "error";
                state.message = payload?.message ?? "No device found"
            } else {
                state.status = "loaded";
                state.message = payload?.message
                state.data = payload;
            }
        }).addCase(getDeviceDetailThunk.rejected, (state, action) => {
            state.event = "detail_device"
            state.status = "error";
            state.message = action.error?.message;
        })

        builder.addCase(devicePaymentThunk.pending, (state) => {
            state.event = "device_payment"
            state.status = "loading";
        }).addCase(devicePaymentThunk.fulfilled, (state, { payload }) => {
            state.event = "device_payment"

            if (payload && payload.length <= 0) {
                state.status = "error";
                state.message = payload?.message ?? "No device found"
            } else {
                state.status = "success_paymemt";
                state.message = payload?.message
                state.data = payload;
            }
        }).addCase(devicePaymentThunk.rejected, (state, action) => {
            state.event = "device_payment"
            state.status = "error";
            state.message = action.error?.message;
        })
        
        builder.addCase(deviceSubscribeThunk.pending, (state) => {
            state.event = 'device_subsribe';
            state.status = "loading";
        }).addCase(deviceSubscribeThunk.fulfilled, (state, action) => {
            state.event = 'device_subsribe';
            var response = action.payload;
            if (!(response?.id)) {
                state.status = "error";
                state.data = null;
                state.message = response?.message ?? "Token not found. Please contact administrator";
            } else {
                state.status = "success";
                // state.message = response?.message;
                // state.data = response?.device
            }
        }).addCase(deviceSubscribeThunk.rejected, (state, action) => {
            state.event = 'device_subsribe';
            state.status = "error";
            state.message = action.error?.message;
        })

        
        builder.addCase(cancelDeviceSubsriptionThunk.pending, (state) => {
            state.event = 'cancel_subsribe';
            state.status = "loading";
        }).addCase(cancelDeviceSubsriptionThunk.fulfilled, (state, action) => {
            state.event = 'cancel_subsribe';
            var response = action.payload;
            if (response && response?.error) {
                state.status = "error";
                state.data = null;
                state.message = response?.error ?? "Token not found. Please contact administrator";
            } else {
                state.status = "success";
                state.message = response?.message;
                // state.data = response?.device
            }
        }).addCase(cancelDeviceSubsriptionThunk.rejected, (state, action) => {
            state.event = 'cancel_subsribe';
            state.status = "error";
            state.message = action.error?.message;
        })
    }
});

export const { resetDeviceState } = deviceSlice.actions;

export const selectDevice = (state) => state.device;

export default deviceSlice.reducer;