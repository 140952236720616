import React, { useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Steps } from '../../features/steps/Steps';
import { useNavigate } from "react-router-dom";


export function Device() {
    const navigate = useNavigate();

    return (
        <>

            <Container fluid className="element-page register-account h-100">
                <Row className="align-items-center h-100">
                    <Steps step={2} />
                    <Col lg={8} className="form-account">
                        <h1>Enter your device code to register this device</h1>
                        <br />
                        <Form.Control type="text" placeholder="1234-ABCD-5678-EFGH-##" />

                        <Row className="align-items-center justify-content-center">
                            <Col lg={6}>
                                <Button variant="secondary" onClick={() => { navigate('/register/account') }}>Cancel</Button>
                            </Col>
                            <Col lg={6} className="text-right">
                                <Button variant="secondary" onClick={() => { navigate('/register/account') }}>Previous</Button>
                                <Button variant="primary" onClick={() => { navigate('/register/profile') }}>Next</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

/* <Container fluid className="element-page register-device">
    <Row className="align-items-center text-center justify-content-center h-100">
        <Col lg={5} md={8}>
            <h1>Enter your device code to register this device</h1>
            <br/>
            <Form.Control type="text" placeholder="1234-ABCD-5678-EFGH-##" />
        </Col>
    </Row>
</Container>
<Container fluid className="element-page footer">
    <Row className="align-items-center justify-content-center">
        <Col lg={8}>
            <Button variant="secondary" onClick={() => { navigate('/register/account')} }>Cancel</Button>
        </Col>
        <Col lg={4} className="text-right">
            <Button variant="secondary" onClick={() => { navigate('/register/account')} }>Previous</Button>
            <Button variant="primary" onClick={() => { navigate('/register/account')} }>Next</Button>
        </Col>
    </Row>
</Container> */

/*
<Container fluid className="element-page">
    <Row className="align-items-center text-center justify-content-center h-100">
        <Col lg={5} md={8}>
            <h1>Enter your device code to register this device</h1>
            <br/>
            <Form.Control type="text" placeholder="1234-ABCD-5678-EFGH-##" />
        </Col>
    </Row>
    <Row className="align-items-center justify-content-center">
        <Col lg={8}>
            <Button variant="secondary" onClick={() => { navigate('/register/account')} }>Cancel</Button>
        </Col>
        <Col lg={4} className="text-right">
            <Button variant="secondary" onClick={() => { navigate('/register/account')} }>Previous</Button>
            <Button variant="primary" onClick={() => { navigate('/register/account')} }>Next</Button>
        </Col>
    </Row>
</Container>
*/