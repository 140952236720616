import React from 'react';
import { useSelector } from 'react-redux';
import {

  BrowserRouter as Router,
  Routes,
  Route,
  Link,

  useNavigate,
  redirect,
  Navigate,

} from "react-router-dom";
import { Splash } from './pages/Splash';
import Login from './pages/Login';
import { Account } from './pages/register/Account';
import { Device } from './pages/register/Device';
import { RegisterProfile } from './pages/register/RegisterProfile';
import { Random } from './pages/register/Random';
import Dashboard from './pages/session/Dashboard';
import Devices from './pages/session/Devices';
import AddDevice from './pages/device/AddDevice';
import Profile from './pages/session/Profile';
import { Settings } from './pages/session/Settings';
import { useAuth } from './providers/AuthProvider';
import AccountNew from './pages/register/AccountNew';
import UpdateDevice from './pages/session/UpdateDevice';

function App(props) {
  const [user] = useAuth();

  return (
    <Router>
      <Routes>
        <Route path="/login" Component={props => {
          if (user) {
            return (
              <Navigate to={'/devices'} />
            )
          }
          return (
            <Login {...props} />
          )
        }} />
        <Route path="/" element={<Splash {...props} />} />

        {/* <Route path="/register/account" element={<Account {...props} />} /> */}
        <Route path="/register/account" element={<AccountNew {...props} />} />
        <Route path="/register/device" element={<Device {...props} />} />
        <Route path="/register/profile" element={<RegisterProfile {...props} />} />
        <Route path="/register/random" element={<Random {...props} />} />

        <Route path="/dashboard" Component={props => {
          if (!user) {
            return <Navigate to={"/"} />
          }

          return <Dashboard {...props} />
        }} />
        <Route path="/devices" Component={props => {
          if (!user) {
            return <Navigate to={"/"} />
          }

          return (<Devices {...props} />)
        }} />
        <Route path="/update-device/:device_id" Component={props => {
          if (!user) {
            return <Navigate to={"/"} />
          }

          return (<UpdateDevice {...props} />)
        }} />
        <Route path="/profile" Component={props => {
          if (!user) {
            return <Navigate to={"/"} />
          }

          return <Profile {...props} />
        }} />
        <Route path="/settings" Component={props => {
          if (!user) {
            return <Navigate to={"/"} />
          }

          return <Settings {...props} />
        }} />

        <Route path="/add-device" Component={props => {
          if (!user) {
            return <Navigate to={"/"} />
          }

          return <AddDevice {...props} />
        }} />

      </Routes>
    </Router>
  );
}

export default App;
