import { createSlice } from "@reduxjs/toolkit"
import { globalKeys, SESSION_TOKEN } from "../../utils/tools";
import { loginThunk, registrationThunk, deviceRegisterThunk, updateUserThunk, getCurrentUser, logoutAuth, deviceSubscribeThunk } from "./authThunks";

const initialState = {
    status: "initial", // initial, loading, error, loaded
    message: "",
    data: {},
    userToken: null,
    userData: {},
    event: "",
}

export const AUTH_EVENTS = {
    login: "login_auth",
    logout: "logout_auth",
    update: "update_auth",
    register: "register_auth",
    register_device: "register_device_auth",
    initial_payment: "initial_payment_auth",
    forgot_password: "forgot_password_auth",
    current_user: "current_user_auth",
    device_subsribe: "device_subsribe",
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {

        callError: (state, { payload }) => {
            state.status = "error_signup";
        },
        checkSession: (state) => {
            // var loginToken = localStorage.getItem(SESSION_TOKEN);
            // var userData = JSON.parse(localStorage.getItem(globalKeys.USER_DATA));

            // if (loginToken) {
            //     state.status = "loaded";
            //     state.message = "success";
            //     state.isLoggedIn = true;
            //     // state.userToken = loginToken;
            //     // state.userData = userData;
            // } else {
            //     state.status = "error_no_session";
            //     state.message = "Token not found. Please contact administrator";
            //     state.isLoggedIn = false;
            //     // state.userToken = null;
            // }
        }
    },
    extraReducers: (builder) => {
        // registration
        builder.addCase(registrationThunk.pending, (state) => {
            state.event = AUTH_EVENTS.register
            state.status = "loading";
        }).addCase(registrationThunk.fulfilled, (state, action) => {
            state.event = AUTH_EVENTS.register
            var response = action.payload;
            if (response?.error || !(response?.user?.token)) { // will remove once the response was fixed
                state.status = "error";
                state.data = response?.message?.errors ?? {};
                state.message = response?.message ?? "Token not found. Please contact administrator";
                state.userToken = null;
            } else {
                // localStorage.setItem(SESSION_TOKEN, response.token)
                state.status = "loaded";
                state.current_step = 2;
                state.data = response?.user;
                state.message = response?._message;
                // state.userData = response?.user ?? {};
                // state.userToken = response?.user?.token ?? '';
                localStorage.setItem(globalKeys.LOGIN_TOKEN, JSON.stringify(response?.user?.token ?? {}));
                // localStorage.setItem(globalKeys.USER_DATA, JSON.stringify(response?.user ?? {}));
            }
        }).addCase(registrationThunk.rejected, (state, action) => {
            state.event = AUTH_EVENTS.register
            state.status = "error";
            state.message = action.error?.message;
        })

        // device registration
        builder.addCase(deviceRegisterThunk.pending, (state) => {
            state.event = AUTH_EVENTS.register_device
            state.status = "loading";
        }).addCase(deviceRegisterThunk.fulfilled, (state, action) => {
            state.event = AUTH_EVENTS.register_device
            var response = action.payload;
            if (!response?.device) {
                state.status = "error";
                state.data = null;
                state.message = response?.message ?? "Token not found. Please contact administrator";
            } else {
                state.status = "loaded";
                state.message = response?.message;
                state.data = response?.device
            }
        }).addCase(deviceRegisterThunk.rejected, (state, action) => {
            state.event = AUTH_EVENTS.register_device
            state.status = "error";
            state.message = action.error?.message;
        })
        
        // forgot password

        // login
        builder.addCase(loginThunk.pending, (state) => {
            state.event = AUTH_EVENTS.login
            state.status = "loading";
        }).addCase(loginThunk.fulfilled, (state, action) => {
            state.event = AUTH_EVENTS.login
            var response = action.payload;
            if (!response?.token || response?.message) {
                state.status = "error";
                state.message = response?.message ?? "Token not found. Please contact administrator";
                state.userToken = null;
            } else {
                // localStorage.setItem(SESSION_TOKEN, response.token)
                state.status = "loaded";
                state.message = "Success";
                state.data = response;
                // state.userToken = response.token;
                // state.userData = response;
                // localStorage.setItem(globalKeys.USER_DATA, JSON.stringify(response));
            }
        }).addCase(loginThunk.rejected, (state, action) => {
            state.event = AUTH_EVENTS.login
            state.status = "error";
            state.message = action.error?.message ?? "Token not found. Please contact administrator";
            state.userToken = null;
        })

        // updateUser
        builder.addCase(updateUserThunk.pending, (state) => {
            state.event = AUTH_EVENTS.update;
            state.status = "loading";
        }).addCase(updateUserThunk.fulfilled, (state, action) => {
            state.event = AUTH_EVENTS.update;
            var response = action.payload;
            if (response?.error || !(response?.user?.token)) { // will remove once the response was fixed
                state.status = "error";
                state.data = response?.message?.errors ?? {};
                state.message = response?.message ?? "Token not found. Please contact administrator";
                state.userToken = null;
            } else {
                // localStorage.setItem(SESSION_TOKEN, response.user.token)
                state.status = "loaded";
                state.current_step = 2;
                state.message = response?.message;
                state.userData = response?.user ?? {};
                state.userToken = response?.user?.token ?? '';
                // localStorage.setItem(globalKeys.USER_DATA, JSON.stringify(response?.user ?? {}));
            }
        }).addCase(updateUserThunk.rejected, (state, action) => {
            state.event = AUTH_EVENTS.update;
            state.status = "error";
            state.message = action.error?.message ?? "Token not found. Please contact administrator";
            state.userToken = null;
        })

        // get current user
        builder.addCase(getCurrentUser.pending, (state) => {
            state.event = AUTH_EVENTS.current_user;
            state.status = "loading";
        }).addCase(getCurrentUser.fulfilled, (state, { payload }) => {
            state.event = AUTH_EVENTS.current_user;
            if (payload?.error || payload?.message) {
                state.status = "error";
                state.message = payload?.message ?? payload?.error ?? "Something went wrong. Please try again";
            } else {
                state.status = "success";
                state.data = payload;
            }
        }).addCase(getCurrentUser.rejected, (state, { error }) => {
            state.event = AUTH_EVENTS.current_user;
            state.status = "error";
            state.message = error?.message ?? "Something went wrong."
        })

        // logout
        builder.addCase(logoutAuth.pending, (state) => {
            state.event = AUTH_EVENTS.logout;
            state.status = "loading";
        }).addCase(logoutAuth.fulfilled, (state, { payload }) => {
            state.event = AUTH_EVENTS.logout;
            if (payload?.error) {
                state.status = "error";
                state.message = payload?.message ?? payload?.error ?? "Something went wrong. Please try again";
            } else {
                state.status = "success";
                state.message = payload?.message
                state.data = payload;
            }
        }).addCase(logoutAuth.rejected, (state, { error }) => {
            state.event = AUTH_EVENTS.logout;
            state.status = "error";
            state.message = error?.message ?? "Something went wrong."
        })
    }
});

export const { logout, checkSession, callError, resetAuthState } = authSlice.actions;

export const selectAuth = (state) => state.auth;

export default authSlice.reducer;