import React, { useEffect, useRef, useReducer } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Header } from '../../features/mobile_header/Header';
import { useNavigate } from "react-router-dom";
import { checkSession, selectAuth } from '../../features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import serializer from 'form-serialize';
import { updateUserThunk, } from '../../features/auth/authThunks';
import { useAuth } from '../../providers/AuthProvider';

function Profile() {
    const navigate = useNavigate();
    const userState = useSelector(selectAuth);
    const [user] = useAuth();
    const dispatch = useDispatch();
    const form = useRef(null);


    const submitUpdateForm = () => {
        // navigate('/register/device')
        const body = serializer(form.current, { hash: true });

        console.log(body);
        dispatch(updateUserThunk(body));
    }

    // const [profileData, setProfileData] = useReducer(user);

    // const handleInput = (e) => {
    //     const { name, value } = e.target;
    //     setProfileData({
    //         [name]: value
    //     })
    // }

    return (
        <>
            <Header page={'profile'}/>
            <Container fluid className="element-page main h-100 pt-5 pb-5">
                <Row className="h-100">
                    <Col lg={12}>
                        <Row className="content">
                            <h1>Edit Profile</h1>
                        </Row>
                        <Form ref={form}>
                            <Row>
                                <Col lg={6}>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" name="first_name" placeholder="John" defaultValue={user.first_name} />
                                    <Form.Control type="hidden" name="id" placeholder="John" defaultValue={user._id} />
                                </Col>
                                <Col lg={6}>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" name="last_name" placeholder="Citizen" defaultValue={user.last_name} />
                                </Col>
                                <Col lg={6}>
                                    <Form.Label>Contact No.</Form.Label>
                                    <Form.Control type="text" name="contact_no" placeholder="0044" defaultValue={user.contact_no} />
                                </Col>
                                <Col lg={12}>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" name="email" disabled="disabled" placeholder="email" defaultValue={user.email} />
                                </Col>
                                <Col lg={6}>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" name="password" placeholder="Password" />
                                </Col>
                                <Col lg={6}></Col>
                                <Col lg={6} className="text-right">
                                    <Button className="d-table" variant="secondary" onClick={() => navigate('/')}>Cancel</Button>
                                </Col>
                                <Col lg={6} className="text-right">
                                    {(() => {
                                        const { status, message, } = userState;

                                        if (status === "initial") {
                                            return (
                                                <Button className="d-table" variant="primary" onClick={() => submitUpdateForm()}>Save</Button>
                                            )
                                        }

                                        if (status === "loading") {
                                            return (
                                                <Button className="d-table" variant="primary" >Loading</Button>
                                            )
                                        }

                                        if (["error_no_session", "error"].includes(status)) {
                                            return (
                                                <Button className="d-table" variant="primary" onClick={() => submitUpdateForm()}>Save</Button>
                                            )
                                        }

                                        return (
                                            <Button className="d-table" variant="primary" onClick={() => submitUpdateForm()}>Save</Button>
                                        )
                                    })()}
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}


export default Profile