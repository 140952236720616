import { createAsyncThunk } from "@reduxjs/toolkit";
import { authSignIn, authSignUp, deviceRegister, authUpdateUser, authCurrentUser, logoutUser } from "./authApi";

// mocking API
const mockAwait = (data, isOnlyEmail) => new Promise((resolve, reject) => {
    setTimeout(() => {
        if (!data?.email) {
            reject(new Error("No Email"));
            return;
        }
        if (!isOnlyEmail && !data?.password) {
            reject(new Error("No Password"));
            return;
        }
        resolve(data);
    }, 1000);
})

export const registrationThunk = createAsyncThunk(
    'auth/registration',
    async (data) => {
        return await authSignUp(data);
    }
)

export const deviceRegisterThunk = createAsyncThunk(
    'device/register',
    async (data) => {
        return await deviceRegister(data);
    }
)

export const loginThunk = createAsyncThunk(
    'auth/login',
    async (data) => {
        return await authSignIn(data);
    }
)

export const forgotPassword = createAsyncThunk(
    'auth/forgot-password',
    async (data) => {
        return await mockAwait(data, true);
    }
)

export const getCurrentUser = createAsyncThunk(
    'auth/getCurrentUser',
    async () => {
        return await authCurrentUser();
    }
)

export const logoutAuth = createAsyncThunk(
    'auth/logout',
    async () => {
        return await logoutUser();
    }
)

export const updateUserThunk = createAsyncThunk(
    'auth/update',
    async (data) => {
        return await authUpdateUser(data);
    }
)