import { createSlice } from "@reduxjs/toolkit"
import { subscribeCustomerThunk, createCardTokenThunk } from "./stripeThunks";

const initialState = {
    from: 'stripe',
    status: "initial", // initial, loading, error, loaded
    message: "",
    data: {},
    current_step: 4,
}

export const stripeSlice = createSlice({
    name: "stripe",
    initialState,
    reducers: {
        resetStripeState: (state) => {
            state.from = 'stripe';
            state.status = "initial";
            state.message = "";
            state.data = {};
            state.current_step = 4;
        }
    },
    extraReducers: (builder) => {
        // subscribe
        builder.addCase(subscribeCustomerThunk.pending, (state) => {
            state.status = "loading";
        }).addCase(subscribeCustomerThunk.fulfilled, (state, action) => {
            var response = action.payload;
            console.log(response, "subscribeCustomerThunk")
            if (response?.id) {
                state.status = 'success';
                state.data = response;
            } else {
                state.status = "error";
                state.message = `${response?.error?.message ?? "Invalid Transaction Please try again"}`.substring(0, 120)
            }
        }).addCase(subscribeCustomerThunk.rejected, (state, action) => {
            state.status = "error";
            state.message = action.error?.message;
        })
        // create Token
        builder.addCase(createCardTokenThunk.pending, (state) => {
            state.status = "loading";
        }).addCase(createCardTokenThunk.fulfilled, (state, action) => {
            var response = action.payload;
            console.log(response, "create card token")
            if (response?.error) {
                state.status = 'error';
                state.message = response?.error?.message ?? '';
            } else if (response?.id) {
                state.status = 'success-token';
                state.data = { cardToken: response?.id ?? '' }
            }
        }).addCase(createCardTokenThunk.rejected, (state, action) => {
            state.status = "error";
            state.message = action.error?.message;
        })
    }
});

export const { resetStripeState } = stripeSlice.actions;

export const selectStripe = (state) => state.stripe;

export default stripeSlice.reducer;