import { createAsyncThunk } from "@reduxjs/toolkit";
import { cancelDeviceSubscription, deviceDetail, deviceList, devicePayment, deviceSubscribe, updateDevice } from "./deviceApi";
import { deviceRegister } from "../auth/authApi";


export const deviceListThunk = createAsyncThunk(
    'device/list_devices',
    async (data) => {
        return await deviceList(data);
    }
)

export const updateDeviceThunk = createAsyncThunk(
    'device/update_device',
    async (data) => {
        return await updateDevice(data);
    }
)

export const createDeviceThunk = createAsyncThunk(
    'device/create',
    async (payload) => {
        return await deviceRegister(payload)
    }
)

export const getDeviceDetailThunk = createAsyncThunk(
    'device/detail_device',
    async (payload) => {
        return await deviceDetail(payload)
    }
)
export const devicePaymentThunk = createAsyncThunk(
    'device/payment',
    async (payload) => {
        return await devicePayment(payload)
    }
)

export const deviceSubscribeThunk = createAsyncThunk(
    'device/susbcribe',
    async (data) => {
        return await deviceSubscribe(data);
    }
)

export const cancelDeviceSubsriptionThunk = createAsyncThunk(
    'device/cancel_susbcribe',
    async (data) => {
        return await cancelDeviceSubscription(data);
    }
)
