import { customFetch, globalKeys } from "../../utils/tools";

export const authSignUp = async ({
    primary_device_id,
    first_name,
    last_name,
    contact_no,
    username,
    type,
    email,
    password,
    password_confirmation,
}) => {
    return await customFetch({
        api_uri: 'auth/signup',
        body: {
            primary_device_id,
            first_name,
            last_name,
            contact_no,
            username,
            type,
            email,
            password,
            password_confirmation,
        },
        method: "POST"
    });
}

export const authCurrentUser = async () => {
    return await customFetch({
        api_uri: 'auth/me',
        method: "GET",
        includeHeaders: true,
    });
}

export const logoutUser = async () => {
    return await customFetch({
        api_uri: 'auth/signout',
        method: "POST",
        includeHeaders: true,
        body: {}
    });
}

export const deviceRegister = async ({
    name,
    device_id,
    user_id,
    screen,
    brightness,
    mode,
    isPaidSubscription,
    cryptoCurrency,
    cryptoCurrencyId,
    primaryCurrency
}) => {
    return await customFetch({
        api_uri: 'device/register',
        body: {
            name,
            device_id,
            user_id,
            screen,
            brightness,
            mode,
            isPaidSubscription,
            cryptoCurrency,
            cryptoCurrencyId,
            primaryCurrency
        },
        method: "POST",
        includeHeaders: true,
    });
}

export const authSignIn = async ({
    primary_device_id,
    email,
    password,
}) => {
    return await customFetch({
        api_uri: 'auth/signin',
        body: {
            primary_device_id,
            email,
            password,
        },
        method: "POST"
    });
}

export const authUpdateUser = async ({
    first_name,
    last_name,
    contact_no,
    password,
    password_confirmation,
    id,
}) => {
    return await customFetch({
        api_uri: 'auth/update',
        body: {
            first_name,
            last_name,
            contact_no,
            password,
            password_confirmation,
            id,
        },
        method: "POST",
        includeHeaders: true,
    });
}

