import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { resetStripeState, selectStripe } from '../../features/stripe/stripeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeCustomerThunk, createCardTokenThunk } from '../../features/stripe/stripeThunks';
import { toast } from 'react-toastify';
import { SideNav } from '../../features/sidenav/SideNav';
import * as Icon from 'react-bootstrap-icons';
import { Header } from '../../features/mobile_header/Header';
import { useAuth } from '../../providers/AuthProvider';
import useLocalStorage from '../../hooks/useLocalStorage';
import { resetDeviceState, selectDevice } from '../../features/devices/deviceSlice';
import { createDeviceThunk, deviceSubscribeThunk, updateDeviceThunk } from '../../features/devices/deviceThunks';
import CheckoutForm from '../../components/CheckoutForm';
import {loadStripe} from '@stripe/stripe-js'
import {Elements, useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';

const ADD_DEVICE_LOG = "add_device_log";

function AddDevice() {
    const { REACT_APP_PAYMENT_PKEY } = process.env;
    const stripePromise = loadStripe(REACT_APP_PAYMENT_PKEY);

    const toastId = useRef();
    const [toastGet, toastSet] = useState(toastId.current);
    const [deviceRecord, setDeviceRecord] = useLocalStorage(ADD_DEVICE_LOG, null)
    const navigate = useNavigate();
    const stripeState = useSelector(selectStripe);
    var [user] = useAuth()
    const dispatch = useDispatch();
    const { status, message, event, data } = useSelector(selectDevice);

    const { status: stripeStatus, message: stripeMessage, data: stripeData } = stripeState;

    const [currentStep, setCurrentStep] = useState(1);
    const [paymentStep, setPaymentStep] = useState(1);
    const [subscritionType, setSubscritionType] = useState('Free');

    const [deviceData, setDeviceData] = useState({
        user_id: user?.id,
        device_id: "",
        name: "",
        screen: 1,
        cryptoCurrency: ["BTC"],
        cryptoCurrencyId: [1],
        primaryCurrency: "USD"
    });

    const [cardData, setCardData] = useState(
        {
            number: '',
            cvc: '',
            exp_month: '',
            exp_year: '',
            email: '',
            name: ''
        });

    useEffect(() => {
        if (event === "device_subsribe") {
            if (status === "loading") {
                toastSet(toast.loading("Please wait loading...", {
                    isLoading: true,
                }))
            }
			if (status === "error") {
				toast.error(toastGet, {
					type: "error",
					isLoading: false,
					render: message,
					autoClose: 2000,
					theme: "colored",
				})
			}
			if (status === "success") {
                toast.update(toastGet, {
                    type: "success",
                    render: 'Successfuly added device',
                    isLoading: false,
                    autoClose: 2000
                })
                submitToLogin();
            }
        }

    }, [status])

    const submitDeviceRegisterForm = () => {
        dispatch(createDeviceThunk(deviceData));
    }

    useEffect(() => {
        // * init checker if there's an existing registration process;
        var registerProcess = JSON.parse(deviceRecord)
        if (registerProcess?.device) {
            // * set's device to step 1
            setDeviceData(registerProcess?.device);
            setCurrentStep(2);
        }

    }, [])
    
    const submitToLogin = async () => {
        // await dispatch(createDeviceThunk(deviceData));
        
        // toast.success(toastGet, {
        //     type: "success",
        //     render: 'Successfully registered for Free',
        //     isLoading: false,
        //     autoClose: 2000
        // });
        setDeviceRecord(null)
        window.localStorage.removeItem(ADD_DEVICE_LOG)
        navigate("/devices", { replace: true, preventScrollReset: true });
    }

    const submitSubscribe = async (pm) => {
        await dispatch(deviceSubscribeThunk({email: user?.email, deviceId: deviceData?.device_id, payment_method: pm?.paymentMethod?.id}));
        submitToLogin();
    }


    const validateField = (field) => {
        // if (userState && userState.data && userState.data[field]) {
        //     return 'required-validate';
        // }
    }

    useEffect(() => {
        if (event === "create_device") {
            if (status === "loading") {
                toastSet(toast.loading("Please wait loading...", {
                    isLoading: true,
                }))
            }

            if (status === "error") {
                toast.update(toastGet, {
                    type: "error",
                    render: message,
                    isLoading: false,
                    autoClose: 2000
                })
            }

            if (status === "success") {
                toast.update(toastGet, {
                    type: "success",
                    render: message,
                    isLoading: false,
                    autoClose: 2000
                })
                // * Proceed to step 2
                // * Save it to add device log
                setDeviceRecord(JSON.stringify({
                    currentStep: 2,
                    device: data
                }))
                setDeviceData(data);
                setCurrentStep(2);
            }
        }

        if (event === "update") {
            if (status === "loading") {
                toastSet(toast.loading("Please wait loading...", {
                    isLoading: true,
                }))
            }

            if (status === "error") {
                toast.update(toastGet, {
                    type: "error",
                    render: message,
                    isLoading: false,
                    autoClose: 2000
                })
            }

            if (status === "update_success") {
                toast.dismiss();
                toast.update(toastGet, {
                    type: "success",
                    render: message,
                    isLoading: false,
                    autoClose: 2000
                })
                // * Proceed to step 2
                // * Save it to add device log
                setDeviceRecord(null)
                window.localStorage.removeItem(ADD_DEVICE_LOG)
                navigate("/devices", { replace: true, preventScrollReset: true });
            }
        }

    }, [event, status])

    useEffect(() => {
        return () => {
            dispatch(resetStripeState());
            dispatch(resetDeviceState());
        }
    }, [])
    console.log('current device', )


    useEffect(() => {
        // * listener for stripe payments

        // * if paid na update profile?
        if (stripeStatus === 'error') {
            console.log(message);
            toast.error(stripeMessage, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
            });
        } else if (stripeStatus === 'success-token') {
            dispatch(subscribeCustomerThunk(stripeData?.cardToken ?? '', cardData));
        } else if (stripeStatus === 'success') {
            toast.success('Success', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
            });
            // * return to devices
            var deviceJson = JSON.parse(deviceRecord);
            dispatch(updateDeviceThunk({
                id: deviceJson?.device?.id,
                subscriptionId: stripeData?.id,
                isPaidSubscription: true,
            }))
            console.log(JSON.parse(deviceRecord), stripeData, {
                id: deviceJson?.device?.id,
                subscriptionId: stripeData?.id,
                isPaidSubscription: true,
            })
        }

    }, [stripeStatus])


    return (
        <>
            <Header page={'devices'} />
            <Container fluid className="element-page main h-100 pt-3 pb-5">
                <Row className="align-items-start justify-content-center h-100 pt-5">
                    {(() => {
                        if (currentStep === 1) {
                            return (
                                <Col lg={8} className="form-account">
                                    <h2>Add Device</h2>
                                    <Row>
                                        <Col lg={12}>
                                            <Form.Label>Device ID</Form.Label>
                                            <Form.Control onChange={e => setDeviceData({ ...deviceData, device_id: e.target.value })} type="text" name="primary_device_id" placeholder="1234-ABCD-5678-EFGH-##" />
                                            <Form.Label>Device Name</Form.Label>
                                            <Form.Control type="text" name="name" onChange={e => setDeviceData({ ...deviceData, name: e.target.value })} />
                                        </Col>
                                        <Col xs={6}>
                                            <Button variant="secondary" onClick={() => navigate('/devices')}>Cancel</Button>
                                        </Col>
                                        <Col xs={6} className="text-right">
                                            <Button variant="primary" onClick={() => submitDeviceRegisterForm()}>Next</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        }


                        if (currentStep === 2) {
                            return (
                                <Col lg={8} className="form-account">
                                    <h1>Payment</h1>
                                    <div className={paymentStep == 1 ? '' : 'hidden'}>
                                        <Row className='align-items-center pricing justify-content-center'>
                                            <Col className='each'>
                                                <label className={(subscritionType == 'Free' ? ' active ' : '')}>
                                                    <h2>Free</h2>
                                                    <ul>
                                                        <li>Receive data updates every 5 minutes at no cost. <br />&nbsp;</li>
                                                    </ul>
                                                    <input type="radio" value={'Free'} className="hidden" onChange={() => setSubscritionType('Free') } name="subscriptionType" />
                                                </label>
                                            </Col>
                                            <Col className='each'>
                                                <label className={(subscritionType == 'Monthly' ? ' active ' : '')}>
                                                    <h2>$15.00/mo</h2>

                                                    <ul>
                                                        <li>Enjoy real-time data refreshes with up to 15-second latency for the most current information.</li>
                                                    </ul>
                                                    <input type="radio" value={'Monthly'} className="hidden" onChange={() => setSubscritionType('Monthly') } name="subscriptionType" />
                                                </label>
                                            </Col>
                                        </Row>  
                                        <Row className="align-items-center justify-content-center gap-1">
                                            <Col xs={12} md={2} className="text-left text-sm-center d-flex d-md-block justify-content-center justify-content-between">
                                                <Button className="my-1 my-md-5" variant="secondary" onClick={() => setCurrentStep(1)}>Cancel</Button>
                                                {(() => {
                                                    if (subscritionType === 'Free') {
                                                        return (
                                                            <>
                                                                <Button className="my-1 my-md-5 d-inline-block d-md-none"  variant="primary" onClick={() => submitToLogin()}>Next</Button>
                                                            </>
                                                        )
                                                    } else {
                                                        return (
                                                            <>
                                                                <Button className="my-1 my-md-5 d-inline-block d-md-none"  variant="primary" onClick={() => setPaymentStep(2)}>Next</Button>
                                                            </>
                                                        )
                                                    }
                                                })()}
                                            </Col>
                                            <Col xs={12} md={8} className="text-right text-xs-center ">
                                                <Button className="my-1 my-md-5 "  variant="secondary" onClick={() => currentStep > 1 ? setCurrentStep(currentStep - 1) : ''}>Previous</Button>
                                                {(() => {
                                                        if (subscritionType === 'Free') {
                                                            return (
                                                                <>
                                                                    <Button className="my-1 my-md-5 d-none d-md-inline-block"  variant="primary" onClick={() => submitToLogin()}>Next</Button>
                                                                </>
                                                            )
                                                        } else {
                                                            return (
                                                                <>
                                                                    <Button className="my-1 my-md-5 d-none d-md-inline-block"  variant="primary" onClick={() => setPaymentStep(2)}>Next</Button>
                                                                </>
                                                            )
                                                        }
                                                })()}
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={paymentStep == 2 ? '' : 'hidden'}>
                                            <Elements stripe={stripePromise}>
                                                <CheckoutForm submitSubscribe={submitSubscribe}/>
                                            </Elements>
                                            <Row className="align-items-center justify-content-center">
                                                <Col xs={6} >
                                                    <Button variant="secondary" onClick={() => setCurrentStep(1)}>Cancel</Button>
                                                </Col>
                                                <Col xs={6} className="text-right">
                                                    <Button variant="secondary" onClick={() => paymentStep > 1 ? setPaymentStep(paymentStep - 1) : ''}>Previous</Button>
                                                </Col>
                                            </Row>
                                    </div>
                                    {/* <Row>
                                        <Col lg={12}>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" onChange={e => setCardData({ ...cardData, email: e.target.value })} placeholder="John" />
                                            <hr />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            Card Information <br />
                                            <Form.Label>Card Number</Form.Label>
                                            <Form.Control type="text" value={cardData?.number} onChange={e => setCardData({ ...cardData, number: e.target.value })} placeholder="1234-1234-1234-1234" />
                                        </Col>
                                        <Col lg={3}>
                                            <Form.Label>Expiry Month</Form.Label>
                                            <Form.Control type="text" onChange={e => setCardData({ ...cardData, exp_month: e.target.value })} placeholder="MM" />
                                        </Col>
                                        <Col lg={3}>
                                            <Form.Label>Expiry Year</Form.Label>
                                            <Form.Control type="text" onChange={e => setCardData({ ...cardData, exp_year: e.target.value })} placeholder="YY" />
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Label>CVC</Form.Label>
                                            <Form.Control type="text" onChange={e => setCardData({ ...cardData, cvc: e.target.value })} placeholder="CVC" />
                                        </Col>
                                        <Col lg={12}>
                                            <Form.Label>Name on card</Form.Label>
                                            <Form.Control type="text" onChange={e => setCardData({ ...cardData, name: e.target.value })} placeholder="John Citizen" />
                                        </Col>
                                        {!deviceData?.id ? <Col lg={6}>
                                            <Button variant="secondary" onClick={() => setCurrentStep(1)}>Previous</Button>
                                        </Col> : <Col lg={6}>
                                            <Button variant="secondary" onClick={() => {
                                                const confirm = window.confirm(`Are you sure you want to cancel transaction? All your data will be lost `)
                                                if (confirm) {
                                                    window.localStorage.removeItem(ADD_DEVICE_LOG);
                                                    navigate('/devices')
                                                }
                                            }}>Cancel</Button>
                                        </Col>}
                                        <Col lg={6} className="text-right">
                                            <Button variant="primary" onClick={() => {
                                                dispatch(createCardTokenThunk(cardData));
                                            }}>Complete Payment</Button>
                                        </Col>
                                    </Row> */}
                                </Col>
                            )
                        }
                    })()}
                </Row>
            </Container>
        </>
    )
}

export default AddDevice;