import { stripeFetch } from "../../utils/tools";

export const stripeCreateCustomer = async ({
    email,
    name
}) => {
    return await stripeFetch({
        api_uri: 'customers',
        body: {
            email,
            name
        },
        method: "POST"
    });
}

export const stripeCreateCardToken = async ({
    number,
    exp_month,
    exp_year,
    cvc,
    name
}) => {
    return await stripeFetch({
        api_uri: `tokens?card[number]=${number}&card[name]=${name}&card[exp_month]=${exp_month}&card[exp_year]=${exp_year}&card[cvc]=${cvc}`,
        method: "POST"
    });
}

export const stripeAttachCard = async (source, customerID) => {
    return await stripeFetch({
        api_uri: `customers/${customerID}/sources?source=${source}`,
        method: "POST"
    });
}

export const stripeSubscribe = async (customerID, priceID) => {
    return await stripeFetch({
        api_uri: `subscriptions?customer=${customerID}&items[0][price]=${priceID}`,
        method: "POST"
    });
}