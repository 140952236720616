import React, { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useNavigate } from "react-router";


export function Splash() {
    const navigate = useNavigate();

    useEffect(() => {
        // mounted
        setTimeout(() => { navigate("/login"); }, 3000);

        return () => {
        }
    }, [])

    return (
        <>
            <Container fluid className="element-page splash h-100">
                <Row className="h-100 align-items-center text-center">
                    <Col className="beat">
                        <h1>Welcome to</h1>
                        <Image src={require('../assets/images/logo.png')} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

