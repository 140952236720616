import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Steps } from '../../features/steps/Steps';
import * as Icon from 'react-bootstrap-icons';


export function Random() {
    const navigate = useNavigate();
    const [type, setType] = useState(false)

    return (
        <>
            <Container fluid className="element-page register-account h-100">
                <Row className="align-items-center h-100">
                    <Steps step={4} />
                    <Col lg={8} className="form-account">
                        <h1>Account Setup</h1>

                    <Row>
                        <Col lg={6}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" placeholder="johncitizen@gmail.com" />
                        </Col>
                        <Col lg={6}>
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" placeholder="johncitizen" />
                        </Col>
                        <Col lg={6}>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" />
                        </Col>
                        <Col lg={6}>
                            <Form.Label>Confirm Password</Form.Label>
                            <InputGroup>
                                <Form.Control type={(!type ? 'password' : 'text')}/>
                                <Icon.EyeFill onClick={() => { setType(!type) }}  />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="align-items-center justify-content-center">
                        <Col lg={6}>
                            <Button variant="secondary" onClick={() => { navigate('/register/account')} }>Cancel</Button>
                        </Col>
                        <Col lg={6} className="text-right">
                            <Button variant="secondary" onClick={() => { navigate('/register/profile')} }>Previous</Button>
                            <Button variant="primary" onClick={() => { navigate('/devices')} }>Next</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        </>
    )
}

