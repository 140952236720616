import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { SideNav } from '../../features/sidenav/SideNav';

export function Settings() {

    return (
        <>
            <SideNav page={'settings'} />
            <Container fluid className="element-page main h-100 pt-3 pb-5">
                <Row className="align-items-center h-100">
                    <Col lg={12}>
                        <Row className="content">
                            <h1>Settings</h1>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

